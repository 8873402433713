




















































































































import type { LayerTypes } from "@/dcmwtconfType";
import Vue from "vue";
import ColorBar from "../DrawerContents/Drawer-colormap/Colorbar.vue";
import DcwmtColormap from "../DrawerContents/Drawer-colormap/Drawer-colormap.vue";

export default Vue.extend({
  components: { ColorBar, DcwmtColormap },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    canRaise: (index: number) => index > 0,
    canLower: function (index: number) {
      return index >= 0 && index < this.layers.length - 1;
    },
    raise: function (index: number) {
      const tmpLayer = this.layers[index];
      this.$set(this.layers, index, this.layers[index - 1]);
      this.$set(this.layers, index - 1, tmpLayer);
    },
    lower: function (index: number) {
      const tmpLayer = this.layers[index];
      this.$set(this.layers, index, this.layers[index + 1]);
      this.$set(this.layers, index + 1, tmpLayer);
    },
    close: function () {
      this.dialog = false;
    },
    save: function (layerIndex: number) {
      const layer = this.layers[layerIndex];
      //@ts-ignore
      const selected = this.$refs.colormap[0].selected;
      if (layer.type === "tone" && Number.isFinite(selected)) {
        layer.clrindex = selected;
        this.$set(this.layers, layerIndex, layer);
      }
      this.dialog = false;
    },
  },
  computed: {
    layers: {
      get: function (): LayerTypes[] {
        return this.$store.getters.drawingOptions.layers;
      },
      set: function (value: LayerTypes[]) {
        const drawingOptions = this.$store.getters.drawingOptions;
        const layers = value;
        this.$store.commit("setDrawingOptions", {
          ...drawingOptions,
          layers,
        });
      },
    },
    colorBarSize: () => ({
      width: "80%",
      height: "20%",
      marginTop: "10px",
    }),
  },
});
