























import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      mathmaticalMethods: [
        { name: "通常", method: (x: number) => x },
        { name: "log10", method: (x: number) => Math.log10(x) },
        { name: "sqrt", method: (x: number) => Math.sqrt(x) },
      ],
    };
  },
  computed: {
    drawinOptions: {
      get: function () {
        return this.$store.getters.drawingOptions;
      },
      set: function (value: { name: string; method: (x: number) => number }) {
        const props = { mathMethod: value.method };
        this.$store.commit("setDrawingOptions", props);
      },
    },
  },
});
