















import type { DrawingOptions } from "@/dcmwtconfType";
import Vue from "vue";
import dcwmt_animation from "./Dcwmt-animation.vue";

type DataType = {
  textValue: string;
};

export default Vue.extend({
  components: {
    dcwmt_animation,
  },
  data(): DataType {
    return {
      textValue: "",
    };
  },
  computed: {
    drawingOptions: {
      set: function (value: DrawingOptions) {
        this.$store.commit("setDrawingOptions", value);
      },
      get: function () {
        return this.$store.getters.drawingOptions;
      },
    },
    definedOptions: function () {
      return this.$store.getters.definedOptions;
    },
    viewerController: function () {
      return this.$store.getters.viewerController;
    },
  },
  methods: {
    onSave: async function () {
      // Take a sum nail image
      const sumneil = this.getSumNailImageData();

      const viewer = this.viewerController.get();
      if (!viewer) {
        return;
      }
      const center = viewer.center;
      const zoom = viewer.zoom;
      const title = this.textValue;
      if (title.length <= 0) {
        alert("タイトルを入力してください.");
        return;
      }

      const drawingOptions = { ...this.drawingOptions, sumneil, title, center, zoom };
      const definedOptions = this.definedOptions;
      const confFile = { definedOptions, drawingOptions };

      // Download json file
      const json = JSON.stringify(confFile);
      const blob = new Blob([json], { type: "application/json" });
      const aEl = document.createElement("a");
      aEl.href = window.URL.createObjectURL(blob);
      aEl.download = "dcwmtmap.json";
      aEl.click();
      aEl.remove();

      this.textValue = "";
    },
    getSumNailImageData: function () {
      const map = document.getElementById("map");
      if (!map) {
        throw new Error("Failed to get map element.");
      }
      const canvas = this.getCanvasElement(map) as HTMLCanvasElement;
      return canvas.toDataURL("image/png");
    },
    getCanvasElement: function (ele: Element): Element {
      if (ele.childElementCount <= 0) {
        return ele;
      }
      return this.getCanvasElement(ele.children[0]);
    },
  },
});
