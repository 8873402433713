















import type { DrawingOptions } from "@/dcmwtconfType";
import Vue from "vue";
import { type ProjCodes, type Projection, projections } from "./projection_lib";

type DrawerFigureDataType = {
  selected: Projection;
};

export default Vue.extend({
  data(): DrawerFigureDataType {
    return {
      selected: projections[0],
    };
  },
  computed: {
    projections: () => projections,
    drawingOptions: {
      get: function (): DrawingOptions {
        return this.$store.getters.drawingOptions;
      },
      set: function (value: DrawingOptions) {
        this.$store.commit("setDrawingOptions", value);
      },
    },
  },
  methods: {
    onClick: function (projection: Projection) {
      const codeObj = { projCode: projection.code as ProjCodes };
      const storeObj = this.drawingOptions;
      this.drawingOptions = { ...storeObj, ...codeObj };
    },
  },
});
